import { getSignatures } from "../commonDynmicForms/signature";
import { FillableBy } from "./form_enums";

export default {
    fillable_by:FillableBy.Evaluator,
    title:
        "KPS Rotaflow Competency Checklist",
    sections: [
        {
            title: "Identify the following components of the circuit:",
            type: "fields",
            fields: [
                {
                    fillable_by : FillableBy.Evaluator,
                    type: "a-checkbox-group",
                    span: 24,
                    value: [],
                    options: [
                        "Power button",
                        "Mains circuit breaker switch",
                        "Flow adjustment knob",
                        "RPM display",
                        "LPM display",
                        "Status display",
                        "Select button",
                        "Set button",
                        "Mute button",
                        "Level override button",
                        "Bubble override button",
                        "LPM mode button",
                        "Zero adjustment button",
                        "Clamp button",
                        "AC power indicator",
                        "Battery operation light",
                        "Battery charging light",
                        "Rotaflow Drive",
                        "Flow meter",
                        "Bubble sensor",
                        "Emergency drive"
                    ].map((item) => ({ text: item, span: 24 }))
                },
            ]
        },
        {
            title: "Demonstrate the following skills:",
            type: "fields",
            fields: [
                {
                    fillable_by : FillableBy.Evaluator,
                    type: "a-checkbox-group",
                    span: 24,
                    value: [],
                    options: [
                        "Turn the Rotaflow console on",
                        "Perform initial Rotaflow boot up sequence",
                        "Describe stand alone mode and features",
                        "Describe free mode and features",
                        "Explain when each mode should be used (ie: for ECMO, for an MCS case with level and bubble sensor in use)",
                        "Demonstrate how to access Rotaflow interface menu",
                        "Put Rotaflow system in free mode operation",
                        "Select screen brightness",
                        "Set low flow limit to 1.0 LPM",
                        "Describe what will happen when flow drops below set low limit",
                        "Set low RPM limit to 1200",
                        "Set high RPM limit to 3000",
                        "Describe what will happen if RPMS are below set low or high limit",
                        "Describe purpose of CLAM in Rotaflow interface menu",
                        "Describe purpose of BTMP in Rotaflow interface menu",
                        "Describe purpose of S-AL in Rotaflow interface menu",
                        "Describe how flow is measured in the Rotaflow system",
                        "Describe how to apply ultrasonic contact cream to flow meter",
                        "Describe error message “SIG!” and how to resolve the error",
                        "Zero the Rotaflow system",
                        "Describe the meanings of the Rotaflow battery lights",
                        "State the battery life of a Rotaflow system on a fully charged internal battery in both volts and minutes",
                        "Unplug the Rotaflow and explain approximate battery life",
                        "Demonstrate how to mute battery operation alarm and explain when system will re-alarm",
                        "Explain at what voltage the Rotaflow system will automatically switch off and an emergency drive or new console use will be required",
                        "Describe required emergency equipment that must be readily available at all times when a Rotaflow system is in use (clamps, oxygen tank, Rotaflow hand crank, Ultrasonic contact paste, back up Rotaflow circuit, ECMO or MCS cart/supplies)",
                        "Demonstrate sequence for using Rotaflow emergency drive hand crank",
                        "Demonstrate sequence for returning circuit to a new Rotaflow system after using the emergency hand crank and sequence for re-initiating support on new Rotaflow console",

                    ].map((item) => ({ text: item, span: 24 }))
                },
            ]
        },
        {
            title: "",
            type: "fields",
            fields: [
                {
                    fillable_by : FillableBy.Evaluator,
                    label: "I have reviewed the KPS Rotaflow Clinical Reference materials. (Employee initials)",
                    placeholder: "Enter your initials",
                    type: "a-input",
                    span: 12,
                    value: "",
                    rules: {
                        required: true,
                        message: "Please provide your initials.",
                    },
                }
            ]
        },
        {
            title: "Signatures",
            type: "signature",
            fields: getSignatures(FillableBy.Evaluator)
          },
    ]
}